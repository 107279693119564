// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative; /* Set position relative to allow absolute positioning of child elements */
  margin-bottom: 15px; /* Adjust as needed for spacing */
}

.confirm-display {
  margin: 3rem 0;
}

.confirm-display h3{
  font-size: 20px;
}

.field-container span, .field-container input {
  width: 90%;
}

.field-label {
  font-weight: bold;
}

.field-container {
  width: 100%;
  margin: auto;
}

.field-container input {
  width: 100%;
}

.personal-details-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #EAEAEA;
  border-radius: 20px;
  padding: 20px 10px;
  margin-bottom: 50px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/ConfirmPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB,EAAE,0EAA0E;EAC9F,mBAAmB,EAAE,iCAAiC;AACxD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".field-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  position: relative; /* Set position relative to allow absolute positioning of child elements */\n  margin-bottom: 15px; /* Adjust as needed for spacing */\n}\n\n.confirm-display {\n  margin: 3rem 0;\n}\n\n.confirm-display h3{\n  font-size: 20px;\n}\n\n.field-container span, .field-container input {\n  width: 90%;\n}\n\n.field-label {\n  font-weight: bold;\n}\n\n.field-container {\n  width: 100%;\n  margin: auto;\n}\n\n.field-container input {\n  width: 100%;\n}\n\n.personal-details-container {\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #EAEAEA;\n  border-radius: 20px;\n  padding: 20px 10px;\n  margin-bottom: 50px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
