// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 10px;
    width: 93%;
    z-index: 10;
    padding-left: 20px;
    padding-top: 30px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 32px 32px 0 0;
  }

  .sticky-header.shadow {
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    .sticky-header {
        width: 560px;
        margin-top: 0;
        border-radius: 0;
        padding-left: 30px;
      }
  }`, "",{"version":3,"sources":["webpack://./src/css/StickyHeader.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM;IACN,uBAAuB;IACvB,aAAa;IACb,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;EAC9B;;EAEA;IACE,0CAA0C;EAC5C;;EAEA;IACE;QACI,YAAY;QACZ,aAAa;QACb,gBAAgB;QAChB,kBAAkB;MACpB;EACJ","sourcesContent":[".sticky-header {\n    position: sticky;\n    top: 0;\n    background-color: white;\n    padding: 10px;\n    width: 93%;\n    z-index: 10;\n    padding-left: 20px;\n    padding-top: 30px;\n    font-size: 12px;\n    font-weight: bold;\n    border-radius: 32px 32px 0 0;\n  }\n\n  .sticky-header.shadow {\n    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);\n  }\n\n  @media (min-width: 768px) {\n    .sticky-header {\n        width: 560px;\n        margin-top: 0;\n        border-radius: 0;\n        padding-left: 30px;\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
