// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.terms-checkbox {
    font-family: Arial, sans-serif; /* Sets the font for the label */
    font-size: 16px; /* Adjust font size */
    display: flex; /* Use flexbox to align checkbox and label text */
    justify-content: flex-start; /* Align items to the start */
    align-items: baseline; /* Align items vertically */
    width: 100%;
}

.terms-checkbox p {
    width: 100%;
}

.terms-checkbox a {
    color: #0645AD;
    text-decoration: none;
}

.terms-checkbox a:hover {
    text-decoration: underline;
}

.terms-checkbox input[type="checkbox"] {
    margin-right: 10px;
}

footer {
    margin-top: 20%;
    margin-bottom: 30px;
}

footer button {
    justify-content: center;
    width: 100%;

}

@media (min-width: 768px) {
    .terms-container {
        max-width: none;
    }
    .terms-agreement {
        width: 60%;
    }

    footer {
        padding: 20px 10px;
        gap: 20px;
        width: 95%;
        position: sticky;
        bottom: 0;
        z-index: 1000;
        background-color: white;
        margin: 0;
    }
}

`, "",{"version":3,"sources":["webpack://./src/css/MiddlePage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,8BAA8B,EAAE,gCAAgC;IAChE,eAAe,EAAE,qBAAqB;IACtC,aAAa,EAAE,iDAAiD;IAChE,2BAA2B,EAAE,6BAA6B;IAC1D,qBAAqB,EAAE,2BAA2B;IAClD,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,WAAW;;AAEf;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,UAAU;IACd;;IAEA;QACI,kBAAkB;QAClB,SAAS;QACT,UAAU;QACV,gBAAgB;QAChB,SAAS;QACT,aAAa;QACb,uBAAuB;QACvB,SAAS;IACb;AACJ","sourcesContent":["label {\n    font-size: 1rem;\n    font-weight: 700;\n    margin-bottom: 0.5rem;\n}\n\n.terms-checkbox {\n    font-family: Arial, sans-serif; /* Sets the font for the label */\n    font-size: 16px; /* Adjust font size */\n    display: flex; /* Use flexbox to align checkbox and label text */\n    justify-content: flex-start; /* Align items to the start */\n    align-items: baseline; /* Align items vertically */\n    width: 100%;\n}\n\n.terms-checkbox p {\n    width: 100%;\n}\n\n.terms-checkbox a {\n    color: #0645AD;\n    text-decoration: none;\n}\n\n.terms-checkbox a:hover {\n    text-decoration: underline;\n}\n\n.terms-checkbox input[type=\"checkbox\"] {\n    margin-right: 10px;\n}\n\nfooter {\n    margin-top: 20%;\n    margin-bottom: 30px;\n}\n\nfooter button {\n    justify-content: center;\n    width: 100%;\n\n}\n\n@media (min-width: 768px) {\n    .terms-container {\n        max-width: none;\n    }\n    .terms-agreement {\n        width: 60%;\n    }\n\n    footer {\n        padding: 20px 10px;\n        gap: 20px;\n        width: 95%;\n        position: sticky;\n        bottom: 0;\n        z-index: 1000;\n        background-color: white;\n        margin: 0;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
