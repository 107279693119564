import React from "react";

const UploadButton = ({ onUpload, disabled }) => {
  return (
    <button
      onClick={onUpload}
      disabled={disabled}
      style={{
        backgroundColor: disabled ? "#ccc" : "#5E4DB2",
        color: "#fff",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      Upload
    </button>
  );
};

export default UploadButton;
