// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    width: auto;
    gap: 10px;
  }
  
  .tab {
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    height: 50px;
  }
  
  .tab.active {
    background-color: var(--button-primary-default);
  }
  
  .tab:hover {
    background-color: var(--button-primary-default-hover);
  }
  
  .tos-content, .privacy-content {
    text-align: left;
    margin-top: 20px;
  }

  .tos-back-button {
    background-color: var(--button-secondary-default);
    justify-content: center;
    width: 75px;
    border-radius: 36px;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 10px;
}
  

  @media (min-width: 768px) {
    .tabs {
      gap: 50px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/ToSPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,SAAS;EACX;;EAEA;IACE,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,+CAA+C;EACjD;;EAEA;IACE,qDAAqD;EACvD;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,iDAAiD;IACjD,uBAAuB;IACvB,WAAW;IACX,mBAAmB;IACnB,6CAA6C;IAC7C,mBAAmB;AACvB;;;EAGE;IACE;MACE,SAAS;IACX;EACF","sourcesContent":[".tabs {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 40px;\n    width: auto;\n    gap: 10px;\n  }\n  \n  .tab {\n    justify-content: center;\n    align-items: center;\n    background-color: #ccc;\n    height: 50px;\n  }\n  \n  .tab.active {\n    background-color: var(--button-primary-default);\n  }\n  \n  .tab:hover {\n    background-color: var(--button-primary-default-hover);\n  }\n  \n  .tos-content, .privacy-content {\n    text-align: left;\n    margin-top: 20px;\n  }\n\n  .tos-back-button {\n    background-color: var(--button-secondary-default);\n    justify-content: center;\n    width: 75px;\n    border-radius: 36px;\n    transition: background-color 0.3s ease-in-out;\n    margin-bottom: 10px;\n}\n  \n\n  @media (min-width: 768px) {\n    .tabs {\n      gap: 50px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
