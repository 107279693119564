// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding-left: 20px;
  position: relative;
  margin-top: -10px; /* Adjust as needed for spacing */
}

.progress-bar-wrapper {
  width: 100%;
  height: 2px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  background-color: #5E4DB2;
  height: 100%;
  transition: width 0.4s ease;
  border-radius: 10px 0 0 10px;
}

.stop-indicator {
  position: absolute;
  top: -5px; /* Adjust the vertical position relative to the progress bar */
  height: 12px;
  width: 12px;
  background-color: #5E4DB2;
  border-radius: 50%;
  transform: translateX(-50%);
  transition: left 0.4s ease;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB,EAAE,iCAAiC;AACtD;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,SAAS,EAAE,8DAA8D;EACzE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;IACV,aAAa;EACf;AACF","sourcesContent":[".progress-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 90%;\n  padding-left: 20px;\n  position: relative;\n  margin-top: -10px; /* Adjust as needed for spacing */\n}\n\n.progress-bar-wrapper {\n  width: 100%;\n  height: 2px;\n  background-color: #f0f0f0;\n  border-radius: 10px;\n  overflow: hidden;\n  position: relative;\n}\n\n.progress-bar {\n  background-color: #5E4DB2;\n  height: 100%;\n  transition: width 0.4s ease;\n  border-radius: 10px 0 0 10px;\n}\n\n.stop-indicator {\n  position: absolute;\n  top: -5px; /* Adjust the vertical position relative to the progress bar */\n  height: 12px;\n  width: 12px;\n  background-color: #5E4DB2;\n  border-radius: 50%;\n  transform: translateX(-50%);\n  transition: left 0.4s ease;\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
