// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideUp {
    from {
      transform: translateY(100%); /* Start from below the screen */
    }
    to {
      transform: translateY(0); /* End at the normal position */
    }
  }
  
  .up-animation-container {
    animation: slideUp 0.5s ease-out forwards; /* Animation name, duration, easing, and fill-mode */
  }

  @keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
    }

    .down-animation-container {
        animation: slideDown 0.5s ease-out forwards;
    }

@keyframes slideInRight {
    from {
      transform: translateX(100%); /* Start from off-screen to the right */
    }
    to {
      transform: translateX(0); /* Slide to its normal position */
    }
  }
  
  .right-animation-container {
    animation: slideInRight 0.5s ease-out forwards; /* Customize the duration and easing to your liking */
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(0%); /* Start from off-screen to the right */
    }
    to {
      transform: translateX(100%); /* Slide to its normal position */
    }
  }
  
  .left-animation-container {
    animation: slideInRight 0.5s ease-out forwards; /* Customize the duration and easing to your liking */
  }`, "",{"version":3,"sources":["webpack://./src/css/animations.css"],"names":[],"mappings":"AAAA;IACI;MACE,2BAA2B,EAAE,gCAAgC;IAC/D;IACA;MACE,wBAAwB,EAAE,+BAA+B;IAC3D;EACF;;EAEA;IACE,yCAAyC,EAAE,oDAAoD;EACjG;;EAEA;IACE;QACI,4BAA4B;QAC5B,UAAU;IACd;IACA;QACI,wBAAwB;QACxB,UAAU;IACd;IACA;;IAEA;QACI,2CAA2C;IAC/C;;AAEJ;IACI;MACE,2BAA2B,EAAE,uCAAuC;IACtE;IACA;MACE,wBAAwB,EAAE,iCAAiC;IAC7D;EACF;;EAEA;IACE,8CAA8C,EAAE,qDAAqD;EACvG;;EAEA;IACE;MACE,yBAAyB,EAAE,uCAAuC;IACpE;IACA;MACE,2BAA2B,EAAE,iCAAiC;IAChE;EACF;;EAEA;IACE,8CAA8C,EAAE,qDAAqD;EACvG","sourcesContent":["@keyframes slideUp {\n    from {\n      transform: translateY(100%); /* Start from below the screen */\n    }\n    to {\n      transform: translateY(0); /* End at the normal position */\n    }\n  }\n  \n  .up-animation-container {\n    animation: slideUp 0.5s ease-out forwards; /* Animation name, duration, easing, and fill-mode */\n  }\n\n  @keyframes slideDown {\n    from {\n        transform: translateY(-100%);\n        opacity: 0;\n    }\n    to {\n        transform: translateY(0);\n        opacity: 1;\n    }\n    }\n\n    .down-animation-container {\n        animation: slideDown 0.5s ease-out forwards;\n    }\n\n@keyframes slideInRight {\n    from {\n      transform: translateX(100%); /* Start from off-screen to the right */\n    }\n    to {\n      transform: translateX(0); /* Slide to its normal position */\n    }\n  }\n  \n  .right-animation-container {\n    animation: slideInRight 0.5s ease-out forwards; /* Customize the duration and easing to your liking */\n  }\n\n  @keyframes slideInLeft {\n    from {\n      transform: translateX(0%); /* Start from off-screen to the right */\n    }\n    to {\n      transform: translateX(100%); /* Slide to its normal position */\n    }\n  }\n  \n  .left-animation-container {\n    animation: slideInRight 0.5s ease-out forwards; /* Customize the duration and easing to your liking */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
