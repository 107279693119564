// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */
.header {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 90% 0%;

    height: 100vh;
    display: flex;
    position: fixed;
    width: 100%;
  }
  
  .logo {
    height: 60px; /* Example size, adjust as needed */
    margin: auto;
  }
  
  @media (min-width: 768px) {
    .header {
      width: 60%;
      height: 100vh;
      background-size: cover;
      background-position: 90% center;
    }
    .logo {
      height: 100px; /* Adjust size as needed */
      margin: 0;
      padding: 50px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/Header.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,yDAA8C;IAC9C,wBAAwB;IACxB,4BAA4B;IAC5B,2BAA2B;;IAE3B,aAAa;IACb,aAAa;IACb,eAAe;IACf,WAAW;EACb;;EAEA;IACE,YAAY,EAAE,mCAAmC;IACjD,YAAY;EACd;;EAEA;IACE;MACE,UAAU;MACV,aAAa;MACb,sBAAsB;MACtB,+BAA+B;IACjC;IACA;MACE,aAAa,EAAE,0BAA0B;MACzC,SAAS;MACT,aAAa;IACf;EACF","sourcesContent":["/* Header.css */\n.header {\n    background-image: url(../assets/images/bg.jpg);\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 90% 0%;\n\n    height: 100vh;\n    display: flex;\n    position: fixed;\n    width: 100%;\n  }\n  \n  .logo {\n    height: 60px; /* Example size, adjust as needed */\n    margin: auto;\n  }\n  \n  @media (min-width: 768px) {\n    .header {\n      width: 60%;\n      height: 100vh;\n      background-size: cover;\n      background-position: 90% center;\n    }\n    .logo {\n      height: 100px; /* Adjust size as needed */\n      margin: 0;\n      padding: 50px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
