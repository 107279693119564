// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base styles for the animation container */
.animation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw; /* Make the container full width */
    height: 100vh;
    max-width: 400px; /* Increase the maximum width for better visibility */
    margin: auto; /* Centering */
    text-align: center;
  }
  
  /* Styling for the Lottie animation specifically */
  .lottie-svg {
    display: flex;
    width: 100%; /* Ensure the SVG fills the container */
    height: auto; /* Maintain aspect ratio */
    margin: auto; /* Increase the space between the animation and the caption */
  }
  
  /* Caption or text below the animation */
  .caption {
    font-size: 20px; /* Increase the font size for better readability on mobile */
    color: #000000; /* Use a darker color for better contrast */
    
    margin-top: -50px; /* Add some space below the caption */
  }
  
  /* Media queries for tablets and desktops */
  @media (min-width: 768px) {
    .animation-container {
      max-width: 600px; /* Allows the animation to be larger on bigger screens */
    }
  
  }

  `, "",{"version":3,"sources":["webpack://./src/css/JsonAnimation.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY,EAAE,kCAAkC;IAChD,aAAa;IACb,gBAAgB,EAAE,qDAAqD;IACvE,YAAY,EAAE,cAAc;IAC5B,kBAAkB;EACpB;;EAEA,kDAAkD;EAClD;IACE,aAAa;IACb,WAAW,EAAE,uCAAuC;IACpD,YAAY,EAAE,0BAA0B;IACxC,YAAY,EAAE,6DAA6D;EAC7E;;EAEA,wCAAwC;EACxC;IACE,eAAe,EAAE,4DAA4D;IAC7E,cAAc,EAAE,2CAA2C;;IAE3D,iBAAiB,EAAE,qCAAqC;EAC1D;;EAEA,2CAA2C;EAC3C;IACE;MACE,gBAAgB,EAAE,wDAAwD;IAC5E;;EAEF","sourcesContent":["/* Base styles for the animation container */\n.animation-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100vw; /* Make the container full width */\n    height: 100vh;\n    max-width: 400px; /* Increase the maximum width for better visibility */\n    margin: auto; /* Centering */\n    text-align: center;\n  }\n  \n  /* Styling for the Lottie animation specifically */\n  .lottie-svg {\n    display: flex;\n    width: 100%; /* Ensure the SVG fills the container */\n    height: auto; /* Maintain aspect ratio */\n    margin: auto; /* Increase the space between the animation and the caption */\n  }\n  \n  /* Caption or text below the animation */\n  .caption {\n    font-size: 20px; /* Increase the font size for better readability on mobile */\n    color: #000000; /* Use a darker color for better contrast */\n    \n    margin-top: -50px; /* Add some space below the caption */\n  }\n  \n  /* Media queries for tablets and desktops */\n  @media (min-width: 768px) {\n    .animation-container {\n      max-width: 600px; /* Allows the animation to be larger on bigger screens */\n    }\n  \n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
