// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --button-primary-default: #4648E2;
    --button-primary-default-hover: #3234C0;
    --button-primary-disabled: rgba(70, 72, 226, 0.50);
    --button-primary-inverted: #4648E2;
    --button-secondary-default: #141519;
    --button-secondary-default-hover: #25282D;
    --button-secondary-disabled: rgba(20, 21, 25, 0.50);
    --button-secondary-inverted: ;
    --button-secondary-inverted-hover: ;
    --button-tertiary-default: #EAEAEA;
    --button-tertiary-default-hover: #C8C8C8;
    --button-tertiary-disabled: rgba(234, 234, 234, 0.50);
    --button-tertiary-inverted: ;
    --button-tertiary-inverted-hover: ;
    --button-sso-default: #FFF;
    --button-sso-default-hover: #C8C8C8;
    --button-sso-disabled: rgba(255, 255, 255, 0.50);
  }`, "",{"version":3,"sources":["webpack://./src/css/variables.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,uCAAuC;IACvC,kDAAkD;IAClD,kCAAkC;IAClC,mCAAmC;IACnC,yCAAyC;IACzC,mDAAmD;IACnD,6BAA6B;IAC7B,mCAAmC;IACnC,kCAAkC;IAClC,wCAAwC;IACxC,qDAAqD;IACrD,4BAA4B;IAC5B,kCAAkC;IAClC,0BAA0B;IAC1B,mCAAmC;IACnC,gDAAgD;EAClD","sourcesContent":[":root {\n    --button-primary-default: #4648E2;\n    --button-primary-default-hover: #3234C0;\n    --button-primary-disabled: rgba(70, 72, 226, 0.50);\n    --button-primary-inverted: #4648E2;\n    --button-secondary-default: #141519;\n    --button-secondary-default-hover: #25282D;\n    --button-secondary-disabled: rgba(20, 21, 25, 0.50);\n    --button-secondary-inverted: ;\n    --button-secondary-inverted-hover: ;\n    --button-tertiary-default: #EAEAEA;\n    --button-tertiary-default-hover: #C8C8C8;\n    --button-tertiary-disabled: rgba(234, 234, 234, 0.50);\n    --button-tertiary-inverted: ;\n    --button-tertiary-inverted-hover: ;\n    --button-sso-default: #FFF;\n    --button-sso-default-hover: #C8C8C8;\n    --button-sso-disabled: rgba(255, 255, 255, 0.50);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
