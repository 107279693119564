import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const UploadProgress = ({ progress, statusMessage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <CircularProgress variant="determinate" value={progress} />
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        {statusMessage}
      </Typography>
    </Box>
  );
};

export default UploadProgress;
